<template>
  <div
    v-if="!loginState"
    class="flex flex-col justify-center items-center h-screen"
  >
    <van-loading color="#0094ff">
      {{ info || '微信授权中，请稍候...' }}
    </van-loading>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import * as Sentry from '@sentry/vue'
export default {
  name: 'WechatOauthIndex',
  data() {
    return {
      exceptionWatcher: null,
      info: ''
    }
  },
  computed: {
    ...mapState('Common/Credential', ['loginState', 'userInfo'])
  },
  created() {
    this.exceptionWatcher = this.$store.subscribe(mutation => {
      if (mutation.type === 'Common/Credential/setExecutionInfo') {
        this.info = mutation.payload
      }
    })
  },
  beforeDestroy() {
    this.exceptionWatcher()
  },
  async mounted() {
    await this.$store.dispatch('Common/Credential/initialize')
    if (this.loginState) {
      // eslint-disable-next-line no-console
      console.log('用户已完成登录', this.loginState)
      let redirect = sessionStorage.getItem('redirect')
      // 登录成功，本地已存在登录态
      if (!redirect) {
        return
      }
      redirect = JSON.parse(redirect)

      // eslint-disable-next-line no-console
      console.warn(
        '[oauth::mounted] 用户已经登录，跳转链接',
        typeof redirect,
        redirect
      )
      let url = window.location.href
      if(url.indexOf('?') > 0) {
        let pos = url.indexOf('?')
        let main = url.substring(0, pos)
        pos = url.indexOf('#')
        let fragment = pos > 0 ? url.substring(pos) : ''
        url = `${main}${fragment}`
        history.replaceState({}, null, url)
      }
      // eslint-disable-next-line no-console
      console.warn(window.location.href)
      Sentry.setUser({
        user: this.userInfo ?? null
      })

      this.$router.replace(redirect)
      sessionStorage.removeItem('redirect')
      return
    }
  }
}
</script>
